import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { a4aData } from '../../../data/app_data';
import AirForAthletesHero from '../../../assets/images/architecture/a4a/a4a___1.jpg';

const Studio = props => {
	let airforathletesImages = a4aData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - airforathletes</title>
					<meta name="description" content="airforathletes" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">NIKE Air for Athletes</h4>
			    <h1 className="brandDescription ">TKTK
</h1>
			    <div className="categories">
			<h5>STORE DESIGN</h5>
			      <h5>3D MODELING </h5>
			      <h5>TECHNICAL DRAWINGS</h5>
			      <h5>RENDER</h5>
			    </div>
				  </div>
					<img src={AirForAthletesHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
					<h2 className="categoryTitle blackText w2">The Project <span>Completed 2024</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
TKTKTKTKTKTK <br/> 
TKTKTKTKTKTK <br/> 
TKTKTKTKTKTK <br/> 
TKTKTKTKTKTK <br/> 
TKTKTKTKTKTK <br/> 								</p>
				</div>
				    {airforathletesImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/ElectricPack">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
