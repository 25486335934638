import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Home from './containers/Home';
import Homelayout from "./hoc/Layout/HomeLayout";
import Layout from './hoc/Layout/Layout';
import About from './components/About/About';
import Homeaux from './hoc/_Aux/_Homeaux';


import Virgil from './components/design/Virgil/Virgil';
import SanFrancisco from './components/design/SanFrancisco/SanFrancisco';
import Sherway from './components/design/Sherway/Sherway';
import SQ1 from './components/design/SQ1/SQ1';
import Soho from './components/design/Soho/Soho';
import Chicago from './components/design/Chicago/Chicago';
import Athletic from './components/design/Athletic/Athletic';
import CarriageHouse from './components/design/CarriageHouse/CarriageHouse';
import CarriageHouse2 from './components/design/CarriageHouse2/CarriageHouse2';
import Cheese from './components/design/Cheese/Cheese';
import Kiddom from './components/design/Kiddom/Kiddom';
import SushiBento from './components/design/SushiBento/SushiBento';
import FWD from './components/design/FWD/FWD';
import Eugene from './components/design/Eugene/Eugene';
import Atlanta from './components/design/Atlanta/Atlanta';
import UES from './components/design/UES/UES';
import Aventura from './components/design/Aventura/Aventura';
import MEC from './components/design/MEC/MEC';
import MarketMall from './components/design/MarketMall/MarketMall';
import SantaMonica from './components/design/SantaMonica/SantaMonica';
import ChiPeg from './components/design/ChiPeg/ChiPeg';
import AirForAthletes from './components/design/AirForAthletes/AirForAthletes';
import Lebron from './components/design/Lebron/Lebron';



class App extends Component {
	render() {
		const routes = (
			<Switch>
				<Route exact path="/Virgil" component={Virgil} />
				<Route exact path="/SanFrancisco" component={SanFrancisco} />
				<Route exact path="/Sherway" component={Sherway} />
				<Route exact path="/SQ1" component={SQ1} />
				<Route exact path="/Soho" component={Soho} />
				<Route exact path="/Chicago" component={Chicago} />
				<Route exact path="/Athletic" component={Athletic} />
				<Route exact path="/CarriageHouse" component={CarriageHouse} />
				<Route exact path="/CarriageHouse2" component={CarriageHouse2} />
				<Route exact path="/Cheese" component={Cheese} />
				<Route exact path="/Kiddom" component={Kiddom} />
				<Route exact path="/SushiBento" component={SushiBento} />
				<Route exact path="/FWD" component={FWD} />
				<Route exact path="/" component={Home} />
				<Route exact path="/about" component={About} />
				<Route exact path="/Eugene" component={Eugene} />
				<Route exact path="/Atlanta" component={Atlanta} />
				<Route exact path="/UES" component={UES} />
				<Route exact path="/Aventura" component={Aventura} />
				<Route exact path="/MEC" component={MEC} />
				<Route exact path="/MarketMall" component={MarketMall} />
				<Route exact path="/SantaMonica" component={SantaMonica} />
				<Route exact path="/ChiPeg" component={ChiPeg} />
				<Route exact path="/AirForAthletes" component={AirForAthletes} />
				<Route exact path="/Lebron" component={Lebron} />
				<Redirect to="/" />
			</Switch>
		);
		return (
			<Homeaux>
			  <Homelayout>{routes}</Homelayout>
			</Homeaux>
		  );
	}
}

export default withRouter(App);


