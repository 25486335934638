import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { lebronData } from '../../../data/app_data';
import LebronHero from '../../../assets/images/architecture/lebron/lebron___2.jpg';


const Lebron = props => {
	let lebronImages = lebronData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - lebron</title>
					<meta name="description" content="NIKE lebron" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE Lebron Pop-up</h4>
				    <h1 className="brandDescription ">189 The Grove Dr<br/>Los Angeles, CA</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={LebronHero} width="100%" alt="NIKE AV" />

				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed 2024</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				  This trialing experience for the new LeBron XXII was built as a temporary structure at The Grove, in its outdoor courtyard just outside the permanent Nike store. Our goal was a retail experience that demonstrated how the LeBron XXII's innovations help athletes overcome high-pressure moments on and off the court. 

Visitors show off your skills with a series of on-court challenges, were able to see how they stack up against the competition and walk away with a digital video from your experience and more.

Difficult site constraints required a compact pop-up structure that could accommodate a basketball court. This trial experience is taking place for a single weekend, requiring the build to be optimized for ease of construction and disassembly. 

As the lead designer, I guided the project from concept development through to execution and teardown.</p>
				</div>
				    {lebronImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/SantaMonica">
						Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Lebron;
